$red: #dd1b51;
$primary: $red;
$secondary: #183253;
$dark: $secondary;
$light: #e8e8e8;

$theme-colors: (
    'red': $red,
);

$btn-border-radius: 0.01rem;
$input-btn-padding-x: 1.5rem;

/* IMPORT BOOTSTRAP */
@import 'bootstrap/scss/bootstrap.scss';

.fullfibre-header {
    background-image: url('../assets/header-bgd@2x.png');
    background-repeat: repeat;
    background-size: 1250px;
}

.what-to-expect {
    background-image: url('../assets/logo-fullfibre@2x.png');
    background-repeat: no-repeat;
    background-size: 25%;
    background-position: bottom, left;
}

.installation-slot {
    background-color: $white;
    color: $secondary;
    &:hover,
    &.active {
        background-color: $red;
        color: white;
        .installation-timeslot,
        .installation-day {
            color: $white;
        }
    }

    .installation-timeslot {
        color: $red;
    }
}

.previous-slot {
    background-color: $red;
    color: $white;
    &:hover,
    &.active {
        background-color: $red;
        .installation-timeslot,
        .installation-day {
            color: $white;
        }
    }

    .installation-timeslot {
        color: $white;
    }
}
